export default {
  colors: {
    primary: {
      main: '#1B65B2',
      light: '#0960A6',
      dark: '#074E87',
    },
    secondary: {
      main: '#0B77CD',
      light: '#0960A6',
      dark: '#074E87',
    },
    background: {
      main: '#FFFFFF',
      surfaces: '#FCFCFC',
    },
    typography: {
      dark: '#1C1413',
      darkBlue: '#325766',
      darkGray: '#444A51',
      gray: '#7A7A7A',
      gray2: '#707170',
      lightGray: '#BAB9B9',
    },
    highlight: {
      lightRed: '#FD867D',
      darkRed: '#9A0000',
      lightGreen: '#D3F4CE',
      lighterBlue: '#F4F7FE',
      lighterBlue2: '#CEDAF2',
      lightBlue: '#11AEF8',
    },
    status: {
      disable: '#EFEFEF',
      success: '#5BB974',
      error: '#EC5C52',
    },
  },
  typography: {
    h1: `700 6rem 'Poppins'`,
    h2: `700 3.2rem 'Poppins'`,
    h3: `700 2.4rem 'Poppins'`,
    h4: `700 1.6rem 'Poppins'`,
    h5: `500 1.4rem 'Poppins'`,
    h6: `700 1.2rem 'Poppins'`,
    h7: `400 1.2rem 'Poppins'`,
  },
};
