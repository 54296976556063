/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import App from '../../App';
import LoginPage from '../../presentation/pages/LoginPage';
import FormRNP from '../../presentation/components/formRNP';

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<LoginPage />} />

          <Route path="rnp" element={<FormRNP />} />
          <Route path="*" element={<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
