import { object, string } from 'zod';

export const schema = object({
  name: string().max(255).optional(),
  cnpj: string().max(255).optional(),
  orgName: string().max(255).optional(),
  email: string().email().optional(),
}).refine(body => body.email, {
  message: 'Email is required',
});
