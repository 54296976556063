export enum MessageTypes {
  SET_DATA = '@message/SET_DATA',
  MESSAGE_ACTIVE = '@message/MESSAGE_ACTIVE',
}

export enum MessageOptions {
  none = 'none',
  ImageModal = 'ImageModal',
}

export type iMessageOptions = keyof typeof MessageOptions;
