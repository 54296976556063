/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import {
  CheckboxChecked,
  IconV4hAgenda,
  IconV4HApi2,
  IconV4hAtendimento,
} from 'presentation/base/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import {
  registerProductsResolver,
  registerProducts,
} from '../../../validation/validators/formRNP/formRNPValidator';
import {
  makeAgendaApiUrl,
  makeApiUrl,
  makeAtendimentoApiUrl,
} from '../../../main/factories/http';
import {
  Button,
  Form,
  Grid,
  Input,
  InputsDiv,
  Label,
  Link,
  MessageError,
  OptionsDiv,
  Products,
  Terms,
  ContainerNewForm,
  ContentNewForm,
  HeaderProducts,
  TitleNewForm,
  SelectProductsNewForm,
} from './styles';

interface iFormProps {
  org: {
    name: string;
    type: 'J' | 'F';
    reg: string;
    skin: 'V4H';
  };
  user: {
    fullName: string;
    email: string;
    phone: string;
    cpf: string;
    gender: 'OTHER';
  };
}

const FormRNP: React.FC = () => {
  const [checkedProducts, setCheckedProducts] = useState<number[]>([]);

  const {
    register,
    setValue,
    setError,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<iFormProps>({
    shouldFocusError: true,
    resolver: registerProductsResolver,
  });

  const productsName = ['V4H Atendimento', 'V4H API', 'V4H Agenda'];

  // e.preventDefault();
  // handleRegisterProducts();

  useEffect(() => {
    const cpf = watch('user.cpf')
      .replace('.', '')
      .replace('.', '')
      .replace('-', '');

    if (cpf === '') return;

    if (cpf.length < 11) {
      setError('user.cpf', {
        type: 'onBlur',
        message: 'O cpf é obrigatório.',
      });
    } else {
      setError('user.cpf', {
        type: 'onBlur',
        message: '',
      });
    }
  }, [setError, watch('user.cpf')]);

  useEffect(() => {
    const phone = watch('user.phone')
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');

    if (phone === '') return;

    if (phone.length < 11) {
      setError('user.phone', {
        type: 'onBlur',
        message: 'O telefone é obrigatório.',
      });
    } else {
      setError('user.phone', {
        type: 'onBlur',
        message: '',
      });
    }
  }, [setError, watch('user.phone')]);

  const handleRegisterProducts = useCallback(async () => {
    if (checkedProducts?.length) {
      if (checkedProducts?.includes(0)) {
        try {
          await registerProducts.validate(getValues());

          axios
            .post(makeAtendimentoApiUrl('/orgs/freemium'), {
              org: {
                name: getValues()?.org.name,
                type: 'J',
                reg: getValues()
                  ?.user?.cpf.replace('.', '')
                  .replace('.', '')
                  .replace('-', ''),
                skin: 'V4H Atendimento',
              },
              user: {
                firstName: getValues()?.user?.fullName.split(' ')[0] || '',
                lastName: getValues()?.user?.fullName.split(' ')[1] || '',
                email: getValues()?.user?.email,
                phone: getValues()?.user?.phone,
                cpf: getValues()
                  ?.user?.cpf.replace('.', '')
                  .replace('.', '')
                  .replace('-', ''),
                gender: 'OTHER',
              },
            })
            .then(() => {
              toast.success('Sucesso ao cadastrar-se no V4H Atendimento!');
            })
            .catch(() => {
              toast.error('Falha ao cadastrar-se no V4H Atendimento!');
            });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            toast.error(err.message);
          }
        }
      }
      if (checkedProducts?.includes(1)) {
        try {
          await registerProducts.validate(getValues());

          axios
            .post(makeApiUrl('/orgs/freemium'), {
              fullname: getValues()?.user?.fullName,
              type: 'F',
              reg: getValues()
                ?.user?.cpf.replace('.', '')
                .replace('.', '')
                .replace('-', ''),
              username: getValues()?.user?.fullName,
              email: getValues()?.user?.email,
              system: 'V4H',
            })
            .then(() => {
              toast.success('Sucesso ao cadastrar-se no V4H API!');
            })
            .catch(() => {
              toast.error('Falha ao cadastrar-se no V4H API!');
            });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            toast.error(err.message);
          }
        }
      }
      if (checkedProducts?.includes(2)) {
        try {
          await registerProducts.validate(getValues());

          axios
            .post(makeAgendaApiUrl('/orgs/freemium'), {
              org: {
                name: getValues()?.org.name,
                type: 'J',
                reg: getValues()
                  ?.user?.cpf.replace('.', '')
                  .replace('.', '')
                  .replace('-', ''),
                skin: 'V4H',
              },
              admin: {
                fullName: getValues()?.user?.fullName,
                email: getValues()?.user?.email,
                phone: `+55${getValues()?.user?.phone}`,
              },
            })
            .then(() => {
              toast.success('Sucesso ao cadastrar-se no V4H AGENDA!');
            })
            .catch(() => {
              toast.error('Falha ao cadastrar-se no V4H AGENDA!');
            });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            toast.error(err.message);
          }
        }
      }
    } else {
      toast.error('Selecione pelo menos um produto!');
    }
  }, [checkedProducts, getValues]);

  return (
    <ContainerNewForm>
      <ContentNewForm>
        <HeaderProducts>
          <TitleNewForm>
            Preencha o formulário e faça um teste gratuito nos produtos V4H.
          </TitleNewForm>
        </HeaderProducts>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SelectProductsNewForm>
            <h4>Selecione os produtos que você deseja testar:</h4>
            <OptionsDiv>
              {productsName?.map((item, index) => (
                <div key={index}>
                  <Products>
                    <input
                      type="checkbox"
                      onChange={e => {
                        if (!e.target.checked)
                          setCheckedProducts(prevState =>
                            prevState.filter(i => i !== index),
                          );
                        else
                          setCheckedProducts(prevState => [
                            ...prevState,
                            index,
                          ]);
                      }}
                    />
                    {item}
                  </Products>
                </div>
              ))}
            </OptionsDiv>
          </SelectProductsNewForm>
          <Form onSubmit={handleSubmit(handleRegisterProducts)}>
            <Grid>
              <InputsDiv>
                <Label htmlFor="name">Nome completo</Label>

                <Input
                  {...register('user.fullName')}
                  type="text"
                  name="user.fullName"
                  required
                  autoFocus
                />
                <MessageError>
                  {Boolean(errors?.user?.fullName) &&
                    errors?.user?.fullName?.message}
                </MessageError>
              </InputsDiv>
              <InputsDiv>
                <Label htmlFor="name">CPF</Label>
                <InputMask
                  {...register('user.cpf')}
                  mask="999.999.999-99"
                  type="text"
                  name="user.cpf"
                  value={watch('user.cpf')}
                  id="cpf"
                  required
                  onChange={e => {
                    setValue('user.cpf', e.target.value.replace(/[^0-9]/g, ''));
                  }}
                >
                  {() => (
                    <Input type="string" id="cpf" name="user.cpf" required />
                  )}
                </InputMask>
                <MessageError>
                  {Boolean(errors?.user?.cpf) && errors?.user?.cpf?.message}
                </MessageError>
              </InputsDiv>
            </Grid>
            <Grid>
              <InputsDiv>
                <Label htmlFor="name">E-mail</Label>
                <Input
                  {...register('user.email')}
                  name="user.email"
                  type="text"
                  id="email"
                  required
                />
                <MessageError>
                  {Boolean(errors?.user?.email) && errors?.user?.email?.message}
                </MessageError>
              </InputsDiv>
              <InputsDiv>
                <Label htmlFor="name">Telefone</Label>
                <InputMask
                  {...register('user.phone')}
                  name="user.phone"
                  mask="(99) 99999-9999"
                  value={watch('user.phone')}
                  type="text"
                  id="cpf"
                  required
                  onChange={e => {
                    const phone = e.target.value.replace(/[^\d]+/g, '').trim();
                    setValue('user.phone', phone);
                  }}
                >
                  {() => <Input type="text" id="phone" required />}
                </InputMask>
                <MessageError>
                  {Boolean(errors?.user?.phone) && errors?.user?.phone?.message}
                </MessageError>
              </InputsDiv>

              <InputsDiv>
                <Label htmlFor="name">Nome da Empresa/Organização</Label>
                <Input
                  {...register('org.name')}
                  name="org.name"
                  type="text"
                  id="company"
                  required
                />
                <MessageError>
                  {Boolean(errors?.org?.name) && errors?.org?.name?.message}
                </MessageError>
              </InputsDiv>
              <Terms>
                Ao informar meus dados, eu concordo com a{' '}
                <Link href="/">Política de Privacidade</Link>
              </Terms>
              <Button type="submit" id="btn-submit">
                Solicitar teste gratuito
              </Button>
            </Grid>
          </Form>
        </div>
      </ContentNewForm>
    </ContainerNewForm>
  );
};

export default FormRNP;
