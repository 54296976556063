/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes } from 'react';

import { IconLogo } from 'presentation/base/icons';
import { Container, Icon } from './styles/StyledButton';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secundary';
  size?: 'mini' | 'small' | 'medium' | 'large' | 'bigger';
  color?: string;
  height?: string;
  nowrap?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rounded?: boolean;
  active?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color,
  type = 'button',
  size = 'default',
  variant = 'primary',
  height = '39px',
  nowrap = false,
  icon,
  rounded = false,
  active = false,
  ...rest
}) => {
  const Logo = icon || IconLogo;

  return (
    <Container
      color={color}
      type={type}
      size={size}
      variant={variant}
      height={height}
      nowrap={nowrap}
      rounded={rounded}
      active={active}
      {...rest}
    >
      {icon && (
        <Icon>
          <Logo fill="white" />
        </Icon>
      )}
      {children}
    </Container>
  );
};

export default Button;
