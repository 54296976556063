import React from 'react';

import Input from 'presentation/components/UI/input';
import { Button } from 'presentation/components/UI';
import { Icon } from 'presentation/components/icon';
import {
  IconOfferOne,
  IconOfferThree,
  IconOfferTwo,
  IconWiseApi,
} from 'presentation/base/icons';

import InputMask from 'react-input-mask';
import { zodResolver } from '@hookform/resolvers/zod';

import { useNavigate } from 'react-router-dom';
import { schema } from 'validation/validators/auth/LoginValidator';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { makeRemoteCreateFreemium } from 'main/factories/usecases/orgs/CreateFreemium';
import {
  Container,
  Left,
  Right,
  Form,
  Body,
  ButtonsContainer,
  Title,
  Offerings,
  Offer,
  WiseServices,
} from './styles/LoginPageStyled';

const FreemiumRegister: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const { register, handleSubmit, control, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      cnpj: '',
      orgName: '',
      email: '',
    },
  });

  const onSubmit = handleSubmit(
    ({
      name,
      cnpj,
      email,
      orgName,
    }: {
      name: string;
      cnpj: string;
      email: string;
      orgName: string;
    }) => {
      makeRemoteCreateFreemium()
        .create({
          fullname: orgName,
          type: 'J',
          reg: cnpj.replace(/\D/g, ''),
          email,
        })
        .then(res => {
          console.log('res: ', res);
          toast.success('Cadastro realizado com sucesso!');
          navigate('/');
        })
        .catch(() => {
          toast.error('Erro ao realizar cadastro!');
        });
    },
  );

  return (
    <Container>
      <Body>
        <Left>
          <Offerings>
            <IconWiseApi />
            <span className="text">
              Utilize a nossa plataforma por um período gratuito de 14 dias.
            </span>
            <WiseServices>O que a Wise API pode oferecer</WiseServices>
            <Offer>
              <Icon src={IconOfferOne} width="64px" height="64px" />
              <span>
                Videoconferência segura, com uma interface de fácil uso.
              </span>
            </Offer>
            <Offer>
              <Icon src={IconOfferTwo} width="64px" height="64px" />
              <span>
                Documentação interativa Swagger, facilitando o manuseio e uso.
              </span>
            </Offer>
            <Offer>
              <Icon src={IconOfferThree} width="64px" height="64px" />
              <span>Integração à outros sistemas facilitada.</span>
            </Offer>
          </Offerings>
        </Left>
        <Right>
          <Form id="loginAuthentication" onSubmit={onSubmit}>
            <Title>Cadastro</Title>
            <Input
              id="name"
              type="text"
              name="name"
              label="Nome Completo"
              placeholder="Digite seu nome completo"
              register={() => register('name')}
              onChange={e => setValue('name', e.target.value.trim())}
              required
            />

            {/* <InputMask
              mask="99.999.999/9999-99"
              onChange={e => setValue('cnpj', e.target.value.trim())}
              value={watch('cnpj')}
            > */}
            <Input
              id="cnpj"
              type="text"
              name="cnpj"
              label="CNPJ"
              placeholder="Digite seu CNPJ"
              register={() => register('cnpj')}
              required
            />
            {/* </InputMask> */}

            <Input
              id="email"
              type="text"
              name="email"
              label="E-mail"
              placeholder="Digite o seu e-mail"
              register={() => register('email')}
              onChange={e =>
                setValue('email', e.target.value.toLowerCase().trim())
              }
              required
            />

            <Input
              id="orgName"
              type="text"
              name="orgName"
              label="Nome da empresa ou organização"
              placeholder="Digite o nome da empresa ou organização"
              register={() => register('orgName')}
              onChange={e => setValue('orgName', e.target.value.trim())}
              required
            />

            <ButtonsContainer>
              <Button id="signupSubmit" type="submit" rounded height="48px">
                Criar minha conta
              </Button>
            </ButtonsContainer>
          </Form>
        </Right>
      </Body>
    </Container>
  );
};

export default FreemiumRegister;
