import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const registerProducts = yup.object().shape({
  org: yup.object().shape({
    name: yup.string().required('O nome é obrigatório.'),
    type: yup.string(),
    reg: yup.string(),
    skin: yup.string(),
  }),
  user: yup.object().shape({
    fullName: yup.string().required('O nome é obrigatório'),
    email: yup
      .string()
      .email('Digite um e-mail válido.')
      .required('O e-mail é obrigatório'),
    phone: yup.string().required('O número é obrigatório'),
    cpf: yup.string().required('O cpf é obrigatório'),
    gender: yup.string(),
  }),
});

export const registerProductsResolver = yupResolver(registerProducts);
