import { makeHttpClient, makeApiUrl } from 'main/factories/http';
import { CreateFreemium as UsecaseRemoteCreateFreemium } from 'domain/usecases/orgs/remote';

import { RemoteCreateFreemium } from 'data/repository/orgs';

/**
 * send request via API.
 */
export const makeRemoteCreateFreemium = (): UsecaseRemoteCreateFreemium =>
  new RemoteCreateFreemium(makeApiUrl('/orgs/freemium'), makeHttpClient());
