import React from 'react';

import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {
  return (
    <>
      {/* <RegisterModal message={message} /> */}
      {children}
    </>
  );
};

export default ConnectComponent(Notifications);
