import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from '../../../data/protocols/http';

class AxiosHttpClient implements HttpClient {
  /**
   * Request for external API.
   * @param data data to make the request.
   */
  public request = async (data: HttpRequest): Promise<HttpResponse> => {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        data: data.body,
        headers: { ...data.headers },
        responseType: data.responseType,
      });
    } catch (error: any) {
      if (error?.response.status === 502 || error?.response.status === 503) {
        toast.error('Falha interna no servidor, tente novamente em minutos.');
      }
      axiosResponse = error?.response;
    }

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  };
}

export default AxiosHttpClient;
