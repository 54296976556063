import styled from 'styled-components';
import BackGroundForm from 'presentation/base/icons/BackgroundRnp.png';

export const OptionsDiv = styled.div`
  display: flex;
  gap: 40px;
`;

export const Products = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 600;

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #fbfbfb;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    background-color: #f29b51;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    left: 10px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  margin-top: 47px;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 20px;
`;

export const InputsDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Terms = styled.div`
  color: #ffffff;
  padding-top: 20px;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
`;

export const Link = styled.a`
  color: #f77321;
  text-decoration: underline;
`;

export const Button = styled.button`
  border-radius: 32px;
  width: 252px;
  background-color: #eb9346;
  border: 1.0049px solid #eb9346;
  box-shadow: 0px 1.0049px 2.0098px rgba(16, 24, 40, 0.05);
  padding: 10px;
  font-size: 18px;
  color: black;
  font-family: 'Inter', sans-serif;

  :hover {
    background-color: #eb9346;
    transition: 0.3s;
  }

  :disabled {
    background-color: #eb9346;
    cursor: not-allowed;
  }
`;

export const Input = styled.input`
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;
  padding: 5px;
  height: 50px;
  font-family: 'Inter', sans-serif;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  line-height: 20px;
  margin-left: 10px;

  ::after {
    content: '*';
    color: #f77321;
  }
`;

export const MessageError = styled.p`
  color: red;
  font-size: 12px;
  font-weight: 500;

  padding: 0px;
  margin-top: 5px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 380px 271px;
  gap: 17px;
`;

/* New Form */
export const ContainerNewForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) {
    padding: 20px 101px 70px 65px;
  }
`;

export const ContentNewForm = styled.div`
  height: 700px;
  display: flex;
  border-radius: 10px;
  background-image: url(${BackGroundForm});
  background-repeat: no-repeat;
  background-size: auto;
  padding: 100px 72px 95px 79px;

  @media (min-width: 1920px) {
    background-size: cover;
    aspect-ratio: 16/9;
  }

  @media (max-height: 720px) {
    padding: 50px 72px 20px 79px;
  }
`;

export const HeaderProducts = styled.div`
  display: flex;
  width: 45%;
  gap: 80px;
  font-family: 'Inter', sans-serif;
`;

export const TitleNewForm = styled.h1`
  color: #ffffff;
  width: 70%;
  font-size: 28px;
  font-weight: 600;
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;

  @media (min-width: 1920px) {
    width: 65%;
  }
`;

export const SelectProductsNewForm = styled.div`
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #333846;
  background-color: transparent;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 24px 0 30px 28px;
  gap: 16px;
  color: white;

  h4 {
    font-size: 20px;
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 600;
  }
`;
