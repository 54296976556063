import React from 'react';

import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from 'styled-components';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import Notifications from './presentation/components/messages';
import store from './data/store';
import { GlobalStyle } from './presentation/base/styles/global';
import { defaultTheme } from './presentation/base/themes';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const persistor = persistStore(store);

const App = (): JSX.Element => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={defaultTheme}>
        <ChakraProvider>
          <Notifications>
            <div className="App">
              <Outlet />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <GlobalStyle />
            </div>
          </Notifications>
        </ChakraProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
